import styled from "@emotion/styled";
import { ReactNode } from "react";
import colors from "../../styles/colors";
import {
  space,
  SpaceProps,
  variant,
  compose,
  LayoutProps,
  layout,
  flexbox,
  FlexboxProps,
  color,
  ColorProps
} from "styled-system";

export interface ButtonProps
  extends SpaceProps,
    LayoutProps,
    FlexboxProps,
    ColorProps {
  variant: "primary" | "secondary";
  children: ReactNode;
  boxSizing?: string;
}

export const ButtonVariant = {
  variants: {
    primary: {
      backgroundColor: colors.mediumLime,
      color: colors.black,
      borderRadius: "6px",
      padding: "1rem 2rem",
      border: "none",
      fontWeight: "bold",
      fontSize: "1rem",
      width: "fit-content",
      cursor: "pointer",
      "&:disabled": {
        cursor: "auto",
        backgroundColor: colors.mediumLime
      },
      "&:hover": {
        backgroundColor: colors.highlightLime
      }
    },
    secondary: {
      backgroundColor: colors.black,
      color: colors.white,
      borderRadius: "6px",
      padding: "1rem 2rem",
      border: "none",
      fontWeight: "bold",
      fontSize: "1rem",
      width: "fit-content",
      cursor: "pointer",
      "&:disabled": {
        cursor: "auto",
        backgroundColor: colors.black
      },
      "&:hover": {
        backgroundColor: colors.grey
      }
    }
  }
};

const Button = styled.button<ButtonProps>(
  compose(variant(ButtonVariant), space, layout, flexbox, color)
);

export default Button;
