import React, { useState } from "react";
import styled from "@emotion/styled";
import App from "../components/App";
import Hero from "../components/Hero";
import Box from "../elements/Box";
import colors from "../styles/colors";
import TextInput from "../components/TextInput";
import Text from "../elements/Text";
import Button from "../elements/Button";
import { PageProps } from "gatsby";
import Span from "../elements/Span";
import Image from "../elements/Image";
import Mastercard from "../images/Payment/Mastercard.svg";
import Paypal from "../images/Payment/Paypal.svg";
import Visa from "../images/Payment/Visa.svg";
import SEO from "../components/SEO";

interface PaymentFormType {
  item_name_1: string | number;
  amount_1: number | null;
  quantity_1: string;
  item_number: string;
  shipping_1: string;
  return: string;
  business: string;
  currency_code: string;
  cmd: string;
  upload: string;
  rm: string;
  mrb: string;
  bn: string;
  Checkout: string;
}

const PaymentInitialValues: PaymentFormType = {
  item_name_1: "",
  amount_1: null,
  quantity_1: "1",
  item_number: "",
  shipping_1: "0.00",
  return: "https://www.acidgreen.com.au",
  business: "payment@acidgreen.com.au",
  currency_code: "AUD",
  cmd: "_cart",
  upload: "1",
  rm: "2",
  mrb: "DKBDRZGU62JYC",
  bn: "UltraProdSAS_SI_ADHOC",
  Checkout: "Pay Now"
};

const Payment = ({ location }: PageProps) => {
  const [form, setForm] = useState<PaymentFormType>(PaymentInitialValues);
  const [loading, setLoading] = useState<boolean>(false);

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const key = e.target.id;
    const value = e.target.value;
    setForm({
      ...form,
      [key]: value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const alteredPayment = {
        ...form,
        amount_1: Math.round((Number(form.amount_1) ?? 0) * 1.025 * 100) / 100
      };
      const response = await fetch("/api/paypal", {
        method: "POST",
        body: JSON.stringify(alteredPayment)
      });
      const data = await response.json();
      if (!data.redirectUrl) {
        throw new Error("Invalid redirect url");
      }
      window.open(data.redirectUrl, "_self");
    } catch (err) {
      console.error("Paypal order could not be initiated.", err);
      setLoading(false);
    }
  };

  return (
    <App location={location}>
      <SEO url={location.href} title="Payment - acidgreen" />
      <Hero heading="Payment"></Hero>
      <Box
        backgroundColor={colors.mediumLime}
        padding={["3rem 0", "3rem 0", "3rem 0", "4rem 0", "8rem 0"]}
        marginBottom="1rem"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxWidth="768px"
          margin="auto"
          width={["90%", "90%", "90%", "80%"]}
        >
          <Form onSubmit={handleSubmit}>
            <TextInput
              required
              id="item_name_1"
              type="text"
              label="Invoice number*"
              onChange={handleInputChange}
              value={form.item_name_1}
              bold
            />
            <TextInput
              required
              id="amount_1"
              type="number"
              step="0.01"
              label="Amount you'd like to pay*"
              onChange={handleInputChange}
              value={form.amount_1}
              bold
            />

            <Text color={colors.darkGrey} margin="1.5rem 0">
              NOTE: 2.5% will automatically be added to the amount to cover
              transaction charges.
            </Text>
            <Box
              display="flex"
              flexDirection="column"
              width={["100%", "100%", "50%"]}
              margin="0 0 0 auto"
            >
              <Button
                variant="secondary"
                type="submit"
                display="flex"
                justifyContent="center"
                marginTop="1rem"
                width="100%"
                disabled={loading}
                backgroundColor={
                  loading ? colors.transparentBlack : colors.black
                }
              >
                {loading ? (
                  <>
                    Redirecting<Span>.</Span>
                    <Span delay="0.2s">.</Span>
                    <Span delay="0.3s">.</Span>
                  </>
                ) : (
                  "Pay now"
                )}
              </Button>
              <Box
                marginTop="1.5rem"
                display="flex"
                justifyContent="space-evenly"
              >
                <Image src={Paypal} alt="MastePaypalrcard" />
                <Image src={Visa} alt="Visa" />
                <Image src={Mastercard} alt="Mastercard" />
              </Box>
            </Box>
          </Form>
        </Box>
      </Box>
    </App>
  );
};

const Form = styled.form``;

export default Payment;
