import React from "react";
import Box from "../../elements/Box";
import Image from "../../elements/Image";
import Text from "../../elements/Text";
import Event1 from "../../images/AboutUs/Events/event1.png";
import Event2 from "../../images/AboutUs/Events/event2.png";
import Event3 from "../../images/AboutUs/Events/event3.png";
import Event4 from "../../images/AboutUs/Events/event4.png";
import Event5 from "../../images/AboutUs/Events/event5.png";
import Event6 from "../../images/AboutUs/Events/event6.png";
import LayoutSection from "../LayoutSection";

const Gallery = () => {
  return (
    <LayoutSection flexDirection="column" fullInnerWidth>
      <Text
        fontSize={[4, 4, 4, 4, 5]}
        textAlign="center"
        fontWeight="bold"
        marginTop="0"
        marginBottom="1rem"
      >
        Work hard play hard
      </Text>
      <Box>
        <Box
          display="flex"
          flexDirection={["column", "column", "row"]}
          justifyContent="center"
        >
          <Image
            src={Event1}
            maxWidth={["100%", "100%", "25%"]}
            margin={["1rem auto", "1rem auto", "0"]}
          />
          <Image
            src={Event2}
            maxWidth={["100%", "100%", "25%"]}
            margin={["1rem auto", "1rem auto", "0"]}
          />
          <Image
            src={Event3}
            maxWidth={["100%", "100%", "50%"]}
            margin={["1rem auto", "1rem auto", "0"]}
          />
        </Box>
        <Box
          display="flex"
          flexDirection={["column", "column", "row"]}
          justifyContent="center"
        >
          <Image
            src={Event4}
            maxWidth={["100%", "100%", "50%"]}
            margin={["1rem auto", "1rem auto", "0"]}
          />
          <Image
            src={Event5}
            maxWidth={["100%", "100%", "25%"]}
            margin={["1rem auto", "1rem auto", "0"]}
          />
          <Image
            src={Event6}
            maxWidth={["100%", "100%", "25%"]}
            margin={["1rem auto", "1rem auto", "0"]}
          />
        </Box>
      </Box>
    </LayoutSection>
  );
};

export default Gallery;
