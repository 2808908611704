import { ClassNames } from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useInView } from "react-intersection-observer";
import AnimatedBox from "../../elements/AnimatedBox";
import AnimatedText from "../../elements/AnimatedText";
import Box from "../../elements/Box";
import Text from "../../elements/Text";
import colors from "../../styles/colors";
import LayoutSection from "../LayoutSection";
import ViewportWrapper from "../ViewportWrapper";

const CoreValues = () => {
  const [ref, inView] = useInView({
    threshold: 0.6,
    triggerOnce: true
  });
  return (
    <ViewportWrapper ref={ref}>
      <LayoutSection
        backgroundColor={colors.black}
        padding={["3rem 0", "3rem 0", "5rem 0", "5rem 0", "5rem 0", "8rem 0"]}
      >
        <Box
          display="flex"
          flexDirection="column"
          margin="auto"
          alignItems="center"
        >
          <AnimatedText
            as="h3"
            fontSize={[4, 4, 5, 5, 6]}
            color={colors.mediumLime}
            fontWeight="normal"
            doesAnimate={inView}
          >
            Our core values
          </AnimatedText>
          <AnimatedText
            color={colors.white}
            textAlign="center"
            doesAnimate={inView}
          >
            At the heart of acidgreen is our integrity which is our unique
            quality of being honest, trustworthy, ethical and transparent with
            everything we do. We don't have any lock-in contracts because we
            believe we should constantly provide exceptional quality &amp;
            excellence to make clients want to stay, not have to. We also
            understand that clients should enjoy working with us so we've
            created a company culture of awesome, likeable people who enjoy
            teamwork, fun and friendship.
          </AnimatedText>
          <Box
            maxWidth="1440px"
            display="flex"
            justifyContent="space-around"
            flexWrap={["wrap", "wrap", "wrap", "nowrap"]}
            width="100%"
            marginTop="1rem"
          >
            <AnimatedBox
              doesAnimate={inView}
              display="grid"
              gridTemplateRows="1fr auto"
              margin="0 0.5rem"
            >
              <ClassNames>
                {({ css }) => (
                  <StaticImage
                    src="../../images/CoreValues/ribbon.svg"
                    alt="quality"
                    objectFit="contain"
                    className={css(imageStyles)}
                  />
                )}
              </ClassNames>
              <Text textAlign="center" color={colors.white}>
                Quality &amp; Excellence
              </Text>
            </AnimatedBox>
            <AnimatedBox
              doesAnimate={inView}
              display="grid"
              gridTemplateRows="1fr auto"
              margin="0 0.5rem"
              delay="0.15s"
            >
              <ClassNames>
                {({ css }) => (
                  <StaticImage
                    src="../../images/CoreValues/handshake.svg"
                    alt="integrity"
                    objectFit="contain"
                    className={css(imageStyles)}
                  />
                )}
              </ClassNames>
              <Text textAlign="center" color={colors.white}>
                Integrity &amp; Transparency
              </Text>
            </AnimatedBox>
            <AnimatedBox
              doesAnimate={inView}
              display="grid"
              gridTemplateRows="1fr auto"
              margin="0 0.5rem"
              delay="0.3s"
            >
              <ClassNames>
                {({ css }) => (
                  <StaticImage
                    src="../../images/CoreValues/thumbsup.svg"
                    alt="awesome"
                    objectFit="contain"
                    className={css(imageStyles)}
                  />
                )}
              </ClassNames>

              <Text textAlign="center" color={colors.white}>
                Be Awesome
              </Text>
            </AnimatedBox>
            <AnimatedBox
              doesAnimate={inView}
              display="grid"
              gridTemplateRows="1fr auto"
              margin="0 0.5rem"
              delay="0.45s"
            >
              <ClassNames>
                {({ css }) => (
                  <StaticImage
                    src="../../images/CoreValues/teamwork.svg"
                    alt="teamwork"
                    objectFit="contain"
                    className={css(imageStyles)}
                  />
                )}
              </ClassNames>

              <Text textAlign="center" color={colors.white}>
                Teamwork &amp; Collaboration
              </Text>
            </AnimatedBox>
            <AnimatedBox
              doesAnimate={inView}
              display="grid"
              gridTemplateRows="1fr auto"
              margin="0 0.5rem"
              delay="0.6s"
            >
              <ClassNames>
                {({ css }) => (
                  <StaticImage
                    src="../../images/CoreValues/heart.svg"
                    alt="fun"
                    objectFit="contain"
                    className={css(imageStyles)}
                  />
                )}
              </ClassNames>
              <Text textAlign="center" color={colors.white}>
                Fun &amp; Friendship
              </Text>
            </AnimatedBox>
          </Box>
        </Box>
      </LayoutSection>
    </ViewportWrapper>
  );
};

const imageStyles = `
  width: 50%;
  margin: 1rem auto;
`;

export default CoreValues;
